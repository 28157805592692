
import gaugeModule from '@/store/modules/gaugeModule';
import guageModule from '@/store/modules/gaugeModule';
import { formatDateAndTime, getComponent, sleep } from '@/utils/helpers';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';

@Component({
  components: {
    GptSidebarPopup: () => getComponent('gpt/GptSidebarPopup'),
    GuageTankDetail: () => getComponent('gpt/GuageTankDetail'),
    GuageRunTicket: () => getComponent('gpt/GuageRunTicket'),
  },
})
export default class GuageForm extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;

  rowData: any = [];

  dataLoading = false;

  showRunTicketModal = false;

  selectedTank: any = null;

  headers = [
    'TANK',
    'EST. VOLUME',
    'LAST GAUGE DAY',
    'LAST GAUGE',
    'LAST RUN TICKET DAY',
    'PROJECTED STATUS',
  ];

  findRecentDates(entries) {
    // Filter entries for gauges (empty run_ticket_link) and run tickets (non-empty run_ticket_link)
    const gauges = entries.filter(entry => entry.run_ticket_link === "");
    const runTickets = entries.filter(entry => entry.run_ticket_link !== "");

    // Function to get the most recent date from an array of entries
    const getMostRecentDate = (arr) => {
        if (arr.length === 0) return null; // Return null if the array is empty
        // Sort by updated_at in descending order and return the first element's updated_at date
        return arr.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())[0].updated_at;
    };

    // Extract the most recent dates for gauges and run tickets
    const lastGaugeDate = getMostRecentDate(gauges);
    const lastRunTicketDate = getMostRecentDate(runTickets);

    return { lastGaugeDate, lastRunTicketDate };
}

  get tanksGuages() {
    return guageModule.tanksGaugesData.map((g) => {
      const vol = g.last_gauge_measurement
        ? this.convertMesuarementTotalbbls(g.last_gauge_measurement)
        : null;
      return {
        ...g,
        lastVolume: vol,
        status: vol && vol > 180 ? 'Ready for haul' : 'Not ready for haul',
        recentData: this.findRecentDates(g.data)
      };
    });
  }

  get nodeTanks() {
    return guageModule.nodeTanks;
  }

  async updateTankGuage(details) {
    console.log(this.selectedTank);
    console.log(details);
    const tankDetails = this.selectedTank;

    const payload = {
      ...tankDetails,
      data: details,
    };
    console.log(payload);
    if (details.length) {
      await gaugeModule.updateGaugeForm(payload);
      await sleep(1000);
      gaugeModule.setIsLoadingGuage(true)
      await this.getTanksDetails();
      gaugeModule.setIsLoadingGuage(false)
    }
  }

  convertMesuarementTotalbbls(measurement, dip_factor = 1.67) {
    var totalInches = 0;

    // Convert feet to inches and add to total
    if (measurement.ft) {
      totalInches += measurement.ft * 12;
    }

    // Add inches to total
    if (measurement.in_) {
      totalInches += measurement.in_;
    }

    // Convert quarter inches to inches and add to total
    if (measurement.qtr_in) {
      totalInches += measurement.qtr_in * 0.25;
    }

    // If dip_factor is provided and is a positive number, multiply the total inches by it
    if (dip_factor && !isNaN(dip_factor) && dip_factor > 0) {
      totalInches *= dip_factor;
    }

    // Return the result as an integer
    return Math.round(totalInches);
  }

  formatDateAndTime(date) {
    const dateObj = new Date(date);
    return formatDateAndTime(dateObj);
  }

  async getTanksDetails() {
    this.dataLoading = true;
    await guageModule.getWellTanks({operator: this.operatorDetails['operatorName'], nodeID: this.tasq.wellName});
    // await guageModule.getNodeTanks({operator: this.operatorDetails['operatorName'], nodeID: this.tasq.wellName})
    this.dataLoading = false;
  }

  async created() {
    await this.getTanksDetails();
  }
}
